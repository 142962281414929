<template>
  <div class="pr-3">
    <base-button
      v-if="hasSgAccess"
      tag="a"
      href="javascript:void(0)"
      class="flex items-center mr-1.5 transform -skew-x-25"
      variant="btn-primary"
      @click="changeRoute({ name: 'StyleGuide' })"
    >
      <template #default>
        <span class="mr-1.5 transform font-semibold skew-x-25 truncate">
          Style Guides
        </span>
      </template>
    </base-button>
    <base-button
      v-if="!$route.path.includes('brand-assurance')"
      tag="a"
      variant="btn-primary"
      href="javascript:void(0)"
      class="flex items-center mr-1.5 transform -skew-x-25"
      @click="changeRoute({ name: 'Basket' })"
    >
      <template #default>
        <span class="mr-1.5 transform font-semibold skew-x-25 truncate">
          Basket
        </span>
        <base-svg
          class="h-3.5 w-3.5 transform skew-x-25 text-white"
          src="icons/basket.svg"
          tag="span"
        />
      </template>
    </base-button>
    <saved-searches-dropdown v-if="!$route.path.includes('brand-assurance')" />
    <base-button
      v-if="hasBaAccess && !$route.path.includes('brand-assurance')"
      tag="a"
      href="javascript:void(0)"
      class="flex items-center mr-1.5 transform -skew-x-25"
      variant="btn-primary"
      @click="changeRoute({ name: 'BrandAssurance' })"
    >
      <template #default>
        <span class="mr-1.5 transform font-semibold skew-x-25 truncate">
          Brand Assurance
        </span>
      </template>
    </base-button>
    <app-logout-dropdown />
    <base-button
      tag="a"
      class="flex items-center mr-1.5 transform -skew-x-25"
      variant="btn-primary"
      @click="onUserGuideClicked()"
    >
      <template #default>
        <span class="mr-1.5 transform font-semibold skew-x-25 truncate">
          User Guides
        </span>
      </template>
    </base-button>
  </div>
</template>

<script>
import { defineAsyncComponent, computed } from 'vue';
import { useStore } from 'vuex';
import { useRouter } from 'vue-router';
import BaseButton from '@/components/generic-components/BaseButton.vue';
import useStyleGuideAssets from '@/hooks/styleGuideAssets.js';

import * as PERMISSIONS from '@/constants/permissions.js';
import * as BA_ROLES from '@/constants/ba-roles.js';

export default {
    name: 'NavigationControllers',

    components: {
        BaseButton,
        baseSvg: defineAsyncComponent(() => import('@/components/generic-components/BaseSvg.vue')),
        SavedSearchesDropdown: defineAsyncComponent(() => import('@/components/style-guide-save-search/SavedSearchesDropdown.vue')),
        AppLogoutDropdown: defineAsyncComponent(() => import('@/components/app-logout-dropdown/AppLogoutDropdown.vue'))
    },

    setup () {
        const store = useStore();
        const router = useRouter();
        const { resetAssetsAndSearch } = useStyleGuideAssets();
        const changeRoute = (newRoute) => {
            resetAssetsAndSearch(newRoute);
            router.push(newRoute);
        };

        const onUserGuideClicked = () => {
            window.open('https://asgardhelp.mvlhub.com', '_blank');
        };

        const proxyUser = computed(() => store.getters['auth/getUserProxyInfo']);
        const currentUserPermissions = store.getters['auth/getUserPermissions'] || [];
        const currenttUserRoles = store.getters['auth/getUserRoles'] || [];

        let userPermissions = [];
        let userRoles = [];

        if (proxyUser.value?.roles) {
            userPermissions = proxyUser.value?.roles;
            userRoles = proxyUser.value?.roles;
        } else {
            userPermissions = currentUserPermissions;
            userRoles = currenttUserRoles;
        }
        const hasBaAccess = userRoles.some(r => Object.values(BA_ROLES).includes(r));
        const hasSgAccess = proxyUser.value?.roles ? proxyUser.value?.roles?.includes('Style Guide Access'):userPermissions.includes(PERMISSIONS.SG_ACCESS_PERMISSION);

        return {
            changeRoute,
            hasBaAccess,
            hasSgAccess,
            onUserGuideClicked,
            proxyUser
        };
    }
};
</script>
